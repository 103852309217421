import "./Bookend.css";

const Bookend = () => {
  return (
    <section>
      <div className="bookend">
        <h2>
          <a href="#top">stevenrolph</a>
        </h2>
        <div className="icons">
          {/* <a href="http://twitter.com" target="_blank" rel="noreferrer">
            <i className="fa-brands fa-twitter"></i>
          </a> */}
          <a
            href="http://github.com/valleyman89"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-github"></i>
          </a>
          <a
            href="http://linkedin.com/in/steven-rolph"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-linkedin"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Bookend;

import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header>
      <div className="header-blurb">
        <h1>
          Nice to meet you! My name is{" "}
          <span className="highlight">Steven Rolph</span>.
        </h1>
        <p>
          I'm a dedicated front-end developer with a passion for{" "}
          <span className="highlight">ReactJS</span>. From crafting seamless
          user interfaces to optimizing performance, I love every aspect of
          building <span className="highlight">dynamic web applications</span>.
        </p>

        <a className="contact" href="#contact">
          contact me
        </a>
      </div>
      <div className="header-photo">
        <img src="/images/profile-pic-2.png" alt="steven rolph" />
      </div>
    </header>
  );
};

export default Header;

import "./Skills.css";

const Skills = () => {
  const skills = [
    { name: "HTML", exp: "Junior-level" },
    { name: "CSS", exp: "Junior-level" },
    { name: "Javascript", exp: "Junior-level" },
    { name: "ReactJS", exp: "Junior-level" },
    { name: "GraphQL", exp: "Junior-level" },
    { name: "TypeScript", exp: "Junior-level" },
    { name: "Redux", exp: "Junior-level" },
  ];

  return (
    <section>
      <hr />

      <div className="skills">
        {skills.map((skill, index) => (
          <div className="skill" key={index}>
            <div className="skill-name">{skill.name}</div>
            <div className="skill-exp">{skill.exp} experience</div>
          </div>
        ))}
      </div>
      <hr />
    </section>
  );
};

export default Skills;

import "./App.css";
import Header from "./components/header";
import Bookend from "./components/common/Bookend";
import Skills from "./components/skills";
import Projects from "./components/projects";
import Contact from "./components/contact";
function App() {
  return (
    <>
      <main className="wrapper">
        <Bookend />
        <Header />
        <Skills />
        <Projects />
      </main>
      <footer>
        <Contact />
      </footer>
    </>
  );
}

export default App;

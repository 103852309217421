import React from "react";
import ProjectLinks from "../common/ProjectLinks";
import "./Projects.css";

const Projects = () => {
  const projects = [
    {
      name: "game hub",
      thumbailSmall: "/images/gamehub-desktop-sm.png",
      thumbailLarge: "/images/gamehub-desktop.png",
      tools: [
        "react",
        "typescript",
        "building compoments",
        "ChakraUI",
        "State",
        "backend services",
      ],
      projectUrl: "https://game-hub-jade-one.vercel.app/",
      repoUrl: "game-hub",
    },
    {
      name: "dictionary app",
      thumbailSmall: "/images/dictionary-desktop.png",
      thumbailLarge: "/images/dictionary-desktop-sm.png",
      tools: ["react", "custom css", "api", "dark mode", "font-toggle"],
      projectUrl: "https://keyboard-api-cyan.vercel.app/",
      repoUrl: "keyboard-api",
    },
    {
      name: "ip address tracker",
      thumbailSmall: "/images/iptracker-desktop.png",
      thumbailLarge: "/images/iptracker-desktop.png",
      tools: ["react", "api", "third-party library"],
      projectUrl: "https://valleyman89.github.io/IP-Address-Tracker",
      repoUrl: "IP-Address-Tracker",
    },
    {
      name: "flag switcher",
      thumbailSmall: "/images/flagswitcher-desktop-sm.png",
      thumbailLarge: "/images/flagswitcher-desktop.png",
      tools: ["react", "api", "context-based", "bootstrap", "filter", "search"],
      projectUrl: "https://valleyman89.github.io/flag-switcher/",
      repoUrl: "flag-switcher",
    },
    {
      name: "technical competency #1",
      thumbailSmall: "/images/thumbnail-project-3-small.webp",
      thumbailLarge: "/images/techtest2-large.png",
      tools: ["react", "JSON", "context-based", "nodeJS"],
      projectUrl: "",
      repoUrl: "feedr-technical-challenge-submission",
    },
    {
      name: "flickr photo stream",
      thumbailSmall: "/images/thumbnail-project-4-small.webp",
      thumbailLarge: "/images/flickr-large.png",
      tools: ["react", "public api", "context-based", "css"],
      projectUrl: "",
      repoUrl: "Flickr-Photo-Stream---Submission",
    },
    {
      name: "technical competency #2",
      thumbailSmall: "/images/thumbnail-project-3-small.webp",
      thumbailLarge: "/images/homeflow-large.png",
      tools: ["react", "context-based", "prop-drilling"],
      projectUrl: "",
      repoUrl: "homeflow",
    },
  ];
  return (
    <section className="projects">
      <div className="project-header">
        projects
        <a className="highlight project-contact" href="#contact">
          contact me
        </a>
      </div>
      <div className="project-wrapper">
        {projects.map((project, index) => (
          <div key={index}>
            <img
              alt={`thumbnail screenshot of ${project.name}`}
              className="project-thumbnail"
              srcSet={`${project.thumbailSmall} 686w, ${project.thumbailLarge} 1024w`}
              sizes="(max-width: 768px) 686px, 1024px"
            />

            <div className="project-name">{project.name}</div>
            <div className="project-tools">
              {project.tools.map((tool, index) => (
                <span className="project-tool" key={index}>
                  {tool}
                </span>
              ))}
            </div>

            <ProjectLinks project={project.projectUrl} repo={project.repoUrl} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Projects;

import React from "react";
import "./ProjectLinks.css";

const ProjectLinks = (data) => {
  return (
    <div className="project-urls">
      {data.project ? (
        <a
          className="highlight"
          href={data.project}
          target="_blank"
          rel="noreferrer"
        >
          view project
        </a>
      ) : null}
      {data.repo ? (
        <a
          className="highlight"
          href={"https://github.com/valleyman89/" + data.repo}
          target="_blank"
          rel="noreferrer"
        >
          view code
        </a>
      ) : null}
    </div>
  );
};

export default ProjectLinks;

import React, { useState } from "react";
import Bookend from "../common/Bookend";
import "./Contact.css";

const form_endpoint =
  "https://public.herotofu.com/v1/400f93d0-9a4f-11ed-82c7-3d7607318e65";

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleSumbit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };
  return (
    <section>
      <div id="contact" className="contact-wrapper">
        <div className="contact-details">
          <div className="contact-title">contact</div>
          <p>
            Feel free to reach out using the contact form below, and I'll get
            back to you as soon as possible. Let's create something amazing
            together!
          </p>
        </div>
        <div className="contact-form">
          <form
            action={form_endpoint}
            onSubmit={handleSumbit}
            method="POST"
            target="_blank"
          >
            <fieldset>
              <input
                className="contact-input border"
                disabled={submitted}
                name="Name"
                placeholder="name"
                maxLength="25"
                required
                type="text"
              />
            </fieldset>
            <fieldset>
              <input
                className="contact-input border"
                disabled={submitted}
                maxLength="50"
                name="Email"
                placeholder="email"
                required
                type="email"
              />
            </fieldset>
            <fieldset>
              <input
                className="contact-input border"
                disabled={submitted}
                maxLength="254"
                name="Message"
                placeholder="message"
                required
                type="text"
              />
            </fieldset>
            <button
              className="contact-submit border"
              disabled={submitted}
              type="submit"
            >
              {submitted ? "thank you!" : "send message"}
            </button>
          </form>
        </div>
      </div>
      <hr />
      <Bookend />
    </section>
  );
};

export default Contact;
